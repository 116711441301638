:root {
  --background: #06090f;
  --cell: #0d1117;
  --cellborder: #30363d;
  --bodytext: #8b949e;
  --titletext: #c9d1d9;

  --wrongborder: #792a31;
  --wrongbackground: #2b161c;
  --rightborder: #1e6a2d;
  --rightbackground: #11221d;
  --highlight: #c842d0;
}


/*Iphone 5*/
@media
  (orientation: portrait) and (min-height: 0px),
  (orientation: landscape) and (min-height: 0px)
{
  :root {
    --secondaryfont: calc(20px - 7px);
    --thirdaryfont: calc(16px - 7px);
    --mainfont: 26px;
    --cellsize: 32px;
    --cellgap: 6px;
  }
}


/*Normal (iphone x)*/
@media
  (orientation: portrait) and (min-height: 668px), /*667px - small mobile*/
  (orientation: landscape) and (min-width: 668px) /*667px*/
{
  :root {
    --secondaryfont: calc(24px - 7px);
    --thirdaryfont: calc(20px - 7px);
    --mainfont: 38px;
    --cellsize: 44px;
    --cellgap: 8px;
  }
}

/*tablet (ipad)*/
@media
  (orientation: portrait) and (min-height: 813px), /*812px - ipad*/
  (orientation: landscape) and (min-width: 813px) /*812px*/
{
  :root {
    --secondaryfont: calc(24px - 4px);
    --thirdaryfont: calc(20px - 4px);
    --mainfont: 38px;
    --cellsize: 44px;
    --cellgap: 8px;
  }
}


/*Ipad pro*/
@media
  (orientation: portrait) and (min-height: 1025px), /*1024px - ipad */
  (orientation: landscape) and (min-width: 1025px) /*1024px*/
{
  :root {
    --secondaryfont: calc(30px - 0px);
    --thirdaryfont: calc(24px - 0px);
    --mainfont: 52px;
    --cellsize: 56px;
    --cellgap: 14px;
  }
}

/*16 inch macbook pro*/
@media
  (orientation: portrait) and (min-height: 1367), /*1366px - ipad pro*/
  (orientation: landscape) and (min-width: 1367) /*1366px*/
{
  :root {
    --secondaryfont: calc(50px - 7px);
    --thirdaryfont: calc(30px - 7px);
    --mainfont: 60px;
    --cellsize: 66px;
    --cellgap: 18px;
  }
}

html {
  touch-action: manipulation;
}
body {

    display: flex;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: hidden;
    background: var(--background);
    color: var(--bodytext);
    user-select: none;
}

#root {

    display: flex;
    flex: 1;
}

.App {
  flex: 1;
    display: flex;
    height: 100%;
}
.Wrong-orientation {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Wrong-orientation-text {
  color: var(--titletext);
  font-size: var(--secondaryfont);
  padding-top: 5px;
}
.Wrong-orientation-picture {
  font-size: var(--mainfont);
  padding-top: 5px;
}

.Game {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
}


@media (orientation: portrait) {
  .Game {
    flex-direction: column;
  }
}

.Player-1-space {
  display: grid;

  grid-template-columns: repeat(6, var(--cellsize));
  grid-template-rows: repeat(6, var(--cellsize));
  column-gap: var(--cellgap);
  row-gap: var(--cellgap);

}
.Player-2-space {
  display: grid;

  grid-template-columns: repeat(6, var(--cellsize));
  grid-template-rows: repeat(6, var(--cellsize));
  column-gap: var(--cellgap);
  row-gap: var(--cellgap);

}

.Score-portrait {
  font-family: 'Press Start 2P'; 
  color: var(--titletext);
  font-size: var(--secondaryfont);
  display: none;
}
.Score-landscape {
  font-family: 'Press Start 2P'; 
  display: flex;
  flex-direction: column;
  color: var(--titletext);
  font-size: var(--thirdaryfont);
  margin-bottom: 20px;
  line-height: calc(var(--thirdaryfont) + 5px);
  text-align: center;
}
@media (orientation: portrait) {
  .Score-portrait {
    display: flex;
  }
  .Score-landscape {
    display: none;
  }
}


.Emoji {
  font-size: var(--mainfont);
  background: var(--cell);
  border: 1px solid var(--cellborder);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  -webkit-text-stroke: 1px var(--titletext);
  -webkit-tap-highlight-color: transparent;
}
.Emoji:active {
  border: 1px solid var(--bodytext);
  background: var(--bodytext);
}

.Emoji-formula {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media (orientation: portrait) {
  .Emoji-formula {
    flex-direction: row;
  }
}

.Formula-component {
  font-size: var(--mainfont);
  display: flex;
  border-radius: 6px;
  color: var(--titletext);
  background: var(--cell);
  border: 1px solid var(--cellborder);

  justify-content: center;
  align-items: center;
  width: var(--cellsize);
  height: var(--cellsize);
  -webkit-text-stroke: 1px var(--titletext);
}

.Formula-sign {
  font-size: var(--secondaryfont);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--cellsize);
  height: var(--cellsize);
}

.Formula-result {
  font-size: var(--mainfont);
  display: flex;
  border-radius: 6px;
  background: var(--cell);
  border: 1px solid var(--cellborder);

  justify-content: center;
  align-items: center;
  width: var(--cellsize);
  height: var(--cellsize);
  -webkit-text-stroke: 1px var(--titletext);
}

.Formula-result-wrong {
 background: var(--wrongbackground);
 border: 1px solid var(--wrongborder); 
 animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}
.Formula-result-right {
 background: var(--rightbackground);
 border: 1px solid var(--rightborder); 
 animation: enlarge 0.6s ease-out both;
}

.Round-counter {
  height: var(--cellsize);
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (orientation: portrait) {
  .Round-counter {
    margin-right: 20px;
  }
}

.Divider-horizontal-internal {
  width: 2002px;
  height: 12px;
  position: absolute;
}
@media (orientation: landscape) {
  .Divider-horizontal {
    display: none;
  }
  .Divider-vertical {
    display: flex;
  }
}

@media (orientation: portrait) {
  .Divider-horizontal {
    display: flex;
    width: 100%;
    height: 11px;
  }
  .Divider-vertical {
    display: none;
  }
}



@media (orientation: portrait) {
  .Divider-horizontal {
    max-width: 100%;
  }
  .Div-hor-cnt {
    max-width: 100%;
  }
}
.Divider-vertical-1 {
  animation: up 2s linear infinite;
}
.Divider-vertical-2 {
  animation: down 2s linear infinite;
}
.Divider-horizontal-1 {
  animation: left 2s linear infinite;
}
.Divider-horizontal-2 {
  animation: right 2s linear infinite;
}
@media (orientation: portrait) {
  .Game .Score-portrait {
    order: 0;
  }
  .Game .Emoji-formula {
    order: 1;
  }
  .Game .Divider-horizontal-1 {
    order: 2;
  }
  .Game .Player-1-space {
    order: 3;
  }
  .Game .Divider-horizontal-2 {
    order: 4;
  }
  .Game .Player-2-space {
    order: 5;
  }
}

.Load-font {
  font-family: 'Press Start 2P', Monospace;
  font-size: 0.001px;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}
.Results {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Press Start 2P', Monospace;
  font-size: 12px;
  justify-content: center;
}
.Results-header {
  padding-bottom: 20px;
  text-align: center;
}
.Results-body {
  display: flex;
  flex-direction: row;  
}
.Player-board {
  flex: 1;
  padding: 0 20px;
}
.Player-name {
  padding-bottom: 40px;
  color: var(--titletext);
  text-align: center;
}
.Board-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px
}
.Board-row>div:last-child {
  color: var(--titletext);
}
.Highlight {
  padding-top: 20px;
}
.Highlight>div:first-child {
  color: var(--highlight);
}
.Results-footer {
  padding-top: 20px;
  text-align: center;
}
.Results-continue {
  background: var(--highlight);
  color: var(--titletext);
  border: none;
  font-family: 'Press Start 2P', cursive;
  padding: 10px 30px;
  cursor: pointer;
}
.Results-continue:focus {
  outline: none;
}
.Results-continue:active {
  color: var(--highlight);
  background: var(--titletext);
}

.Menu {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-evenly;
  max-height: 800px;
  align-self: center;
  min-height: 600px;
}
.Menu-title {
  color: var(--titletext);
  font-family: 'Press Start 2P';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.Menu-score {
  margin: 1rem;
}
.Menu-score-list {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-evenly;
}
.Menu-score-num {
  color: var(--titletext);
  font-family: 'Press Start 2P';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}
.Menu-score-copy {
  color: var(--titletext);
  font-family: 'Press Start 2P';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.Menu-button {
  font-family: 'Press Start 2P';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background-color: transparent;
  color: #BD94FF;
  border: none;
  padding: 20px;
  width: 100%;
}
.Menu-tutorial {
  margin-top: 1rem;
}

.Menu-button:focus {
  outline: none;
}

.Menu-button:active {
  color: black;
  background: #BD94FF;
}

.Tutorial {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 1.5rem;
}

.Tutorial-title {
  color: var(--titletext);
  font-family: 'Press Start 2P';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}
.Tutorial-instruction {
  color: var(--titletext);
  font-family: 'Press Start 2P';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;

}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes enlarge {
  10%, 90% {
    transform: scale(1, 1);
  }
  47%, 50%, 53% {
    transform: scale(3, 3);
  }
}

@keyframes up {
      0% { transform: translateY(90.91px);}
   100% { transform: translateY(0px);}
}
@keyframes down {
      0% { transform: translateY(0px);}
   100% { transform: translateY(90.91px);}
}
@keyframes left {
      0% { transform: translateX(-90.91px);}
   100% { transform: translateX(0px);}
}
@keyframes right {
      0% { transform: translateX(0px);}
   100% { transform: translateX(-90.91px);}
}